import { BaseDto } from '@/shared/dtos/base-dto';

export class convenio extends BaseDto {
    public nombre !: string;
    public codigo !: string;
    public horas_anuales !: number;
    public jornada_intensiva !: boolean;
    public jornada_intensiva_inicio!:Date;
    public jornada_intensiva_fin!:Date;
    public notas !: string;
 } 
