import { store } from "@/store/store";
import { tipos_contrato } from "@/shared/dtos/tipos_contrato";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "tipos_contratoModule",
  store,
  dynamic: true,
})
class tipos_contratoModule extends VuexModule {
  public tipos_contratos: tipos_contrato[] = [];
  public tipos_contrato: tipos_contrato = new tipos_contrato();

  @Action({ commit: "onGettipos_contratos" })
  public async gettipos_contratos() {
    return await ssmHttpService.get(API.tipos_contrato);
  }

  @Action({ commit: "onGettipos_contrato" })
  public async gettipos_contrato(id: any) {
    return await ssmHttpService.get(API.tipos_contrato + "/" + id);
  }

  @Action
  public async guardartipos_contrato(tipos_contrato: tipos_contrato) {
    return await ssmHttpService.post(
      API.tipos_contrato,
      tipos_contrato.toJson()
    );
  }

  @Action
  public async modificartipos_contrato(tipos_contrato: tipos_contrato) {
    return await ssmHttpService.put(
      API.tipos_contrato + "/" + tipos_contrato.id,
      tipos_contrato
    );
  }

  @Action
  public async eliminartipos_contrato(tipos_contrato: tipos_contrato) {
    return await ssmHttpService.delete(
      API.tipos_contrato + "/" + tipos_contrato.id,
      null,
      false
    );
  }

  @Mutation
  public onGettipos_contratos(res: tipos_contrato[]) {
    this.tipos_contratos = res ? res.map((x) => new tipos_contrato(x)) : [];
  }

  @Mutation
  public onGettipos_contrato(res: tipos_contrato) {
    this.tipos_contrato = new tipos_contrato(res);
  }
}
export default getModule(tipos_contratoModule);
