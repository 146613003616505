

























import { Component, Mixins, Prop } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import { hist_contratos_empleado } from "@/shared/dtos/hist_contratos_empleado";
import tipos_contratoModule from "@/store/modules/tipos_contrato-module";
import hist_contratos_empleadoModule from "@/store/modules/hist_contratos_empleado-module";
import convenioModule from "@/store/modules/convenio-module";
import { convenio } from "@/shared/dtos/convenio";
import { FormItem } from "@/components/DinamicallyForm/FormItem";
import { UtilsString } from "@/utils/utils-string";
import datos_empresaModule from "@/store/modules/datos_empresa-module";

@Component({
  components: {},
})
export default class hist_contratos_empleadoFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: hist_contratos_empleado = new hist_contratos_empleado();
  // @Prop({ default: 0 }) id!: number;
  //@Prop({ default: 0 }) id_empleado!: number;

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await hist_contratos_empleadoModule.gethist_contratos_empleado(
        this.getparamId().Parametro
      );
      this.item_pantalla =
        hist_contratos_empleadoModule.hist_contratos_empleado;
    } else {
      this.item_pantalla.fecha_inicio = new Date();
      this.item_pantalla.fecha_fin = new Date();
    }
    this.item_pantalla.id_empleado = Number.parseInt(
      this.$route.params.id_empleado
    );
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
    await tipos_contratoModule.gettipos_contratos();
    await convenioModule.getconvenios();
    await datos_empresaModule.getdatos_empresas();
  }

  ChangeHoras(e: FormItem) {
    if (
      !UtilsString.IsNullOrWhiteSpace(
        UtilsString.ValueOf(this.GetDataItem("id_convenio"))
      )
    ) {
      var horas_anuales = convenioModule.convenios.find(
        (x: convenio) => x.id === this.GetDataItem("id_convenio")
      )!.horas_anuales;
      horas_anuales =
        (this.GetDataItem("porcen_por_conv") * horas_anuales) / 100;

      this.SetFormItem("horas_anuales").data = horas_anuales;
    }
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_inicio),
      "Fecha inicio",
      "fecha_inicio"
    )
      .Dimesiones(12, 6, 4)
      .isRequired();

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_fin),
      "Fecha fin",
      "fecha_fin"
    )
      .Dimesiones(12, 6, 4)
      .isRequired();

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.porcen_por_conv,
      "Horas %",
      "porcen_por_conv"
    )
      .Dimesiones(12, 4, 2)
      .SetValidacion("max_value:110")
      .SetValidacion("min_value:1")
      .isRequired();

    var horas_anuales = 0;

    if (!this.EstoyCreado) {
      horas_anuales = convenioModule.convenios.find(
        (x: convenio) => x.id === this.item_pantalla.id_convenio
      )!.horas_anuales;
      horas_anuales =
        (this.item_pantalla.porcen_por_conv * horas_anuales) / 100;
    }

    this.AddFormItemDataDefaultSlot(
      horas_anuales,
      "Horas totales",
      "horas_anuales",
      2
    ).Dimesiones(12, 4, 2);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_contrato,
      "Contrato",
      "id_contrato"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(
        tipos_contratoModule.tipos_contratos,
        "nombre",
        "id"
      );

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_convenio,
      "Convenios",
      "id_convenio"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(
        convenioModule.convenios,
        "nombre",
        "id"
      );
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_empresa,
      "Empresa",
      "id_empresa"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(
        datos_empresaModule.datos_empresas,
        "nombre",
        "id"
      );
  }

  public Insertar(object: hist_contratos_empleado) {
    object.id_empleado = Number.parseInt(this.$route.params.id_empleado);

    hist_contratos_empleadoModule
      .guardarhist_contratos_empleado(object)
      .then(() => {
        this.AllSaveOk();
      });
  }

  public Actualizar(object: hist_contratos_empleado) {
    object.id_empleado = Number.parseInt(this.$route.params.id_empleado);
    hist_contratos_empleadoModule
      .modificarhist_contratos_empleado(object)
      .then(() => {
       this.AllSaveOk();
      });
  }
}
