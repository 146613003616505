import { store } from '@/store/store';
import { convenio } from '@/shared/dtos/convenio';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'convenioModule',
    store,
    dynamic: true
})
class convenioModule extends VuexModule {
    public convenios: convenio[] = [];
    public convenio: convenio = new convenio();

    @Action({ commit: 'onGetconvenios' })
    public async getconvenios() {
        return await ssmHttpService.get(API.convenio);
    }

    @Action({ commit: 'onGetconvenio' })
    public async getconvenio(id: any) {
        return await ssmHttpService.get(API.convenio + '/' + id);
    }

    @Action
    public async guardarconvenio(convenio: convenio) {
       return await ssmHttpService.post(API.convenio, convenio.toJson());
    }

    @Action
    public async modificarconvenio(convenio: convenio) {
        return await ssmHttpService.put(API.convenio + '/' + convenio.id, convenio);
    }

    @Action
    public async eliminarconvenio(convenio: convenio) {
       return await ssmHttpService.delete(API.convenio + '/' + convenio.id, null, false);
    }

    @Mutation
    public onGetconvenios(res: convenio[]) {
        this.convenios = res ? res.map((x) => new convenio(x)) : []
    }

    @Mutation
    public onGetconvenio(res: convenio) {
        this.convenio = new convenio(res);
    }

}
export default getModule(convenioModule);